html {
    @apply text-sm md:text-base;
}

.body {
    @apply text-gray-700;
}

button {
    touch-action: manipulation;
}
