@import './tailwind';
@import './general';
@import './global/button';
@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';

:root {
    // #FIXME - how to get tailwind colors in scss?
    --swiper-theme-color: #df7232;
}

html {
    @apply bg-white h-full text-base;
}

body {
    padding: env(safe-area-inset-top, 0) env(safe-area-inset-right, 0) env(safe-area-inset-bottom, 0) env(safe-area-inset-left, 0);
    -webkit-font-smoothing: antialiased;
    overscroll-behavior: none;

    &.lock {
        @apply overflow-hidden;
    }
}
