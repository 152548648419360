.btn {
    @apply flex items-center w-max px-9 py-[0.875rem] font-semibold rounded-full relative overflow-hidden text-xs sm:px-11 xl:text-sm 2xl:px-12 transition-colors z-10 whitespace-nowrap text-center justify-center;

    &-sm {
        @apply py-[0.625rem];
    }

    &-narrow {
        @apply px-4;
    }

    span {
        @apply block mx-auto;
    }

    &::before {
        content: '';

        @apply absolute top-0 left-0 bg-eip-dark w-full h-full rounded-full transform -translate-x-[101%] transition-transform duration-300 -z-10;
    }

    &:not(:disabled) {
        @media (hover: hover) {
            &:hover::before {
                @apply sm:translate-x-0;
            }
        }
    }

    &:disabled {
        @apply bg-gray-300 text-white border-none;
    }

    &--empty-white {
        @apply text-white border border-white;
    }

    &--filled {
        @apply text-white bg-secondary;

        @media (hover: hover) {
            &:hover {
                @apply text-white;
            }
        }
    }

    &--empty {
        @apply text-black border border-black;

        @media (hover: hover) {
            &:hover {
                @apply text-white;
            }
        }
    }

    &--full {
        @apply w-full px-0 sm:px-0 2xl:px-0;
    }
}
